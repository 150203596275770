import type { CancelTokenSource } from 'axios';
import type { ReducerAction } from 'shared/interfaces/store/action/reducerAction';
import type { Browsers, BrowsersPerDay, BrowsersTableItem } from 'shared/interfaces/store/browser';
import type { BrowsersPerDayLineChartData, BrowsersPerDayProgressionItem } from './formatter';

import {
    BROWSERS_DATA_IS_LOADING,
    BROWSERS_GET_ALL_FAILURE,
    BROWSERS_GET_ALL_SUCCESS,
    BROWSERS_PER_DAY_DATA_IS_LOADING,
    BROWSERS_PER_DAY_GET_ALL_FAILURE,
    BROWSERS_PER_DAY_GET_ALL_SUCCESS,
    BROWSERS_TABLE_DATA_IS_LOADING,
    BROWSERS_TABLE_GET_ALL_FAILURE,
    BROWSERS_TABLE_GET_ALL_SUCCESS
} from './browsers.types';

export declare interface BrowsersState {
    browsers: Browsers;
    requestSource: CancelTokenSource | null;
    error: string | null;
    isLoading: boolean;

    browsersPerDayLineChartData: BrowsersPerDayLineChartData;
    browsersPerDayProgressionChart: BrowsersPerDayProgressionItem[];
    browsersPerDay: BrowsersPerDay;
    browsersPerDayRequestSource: CancelTokenSource | null;
    browsersPerDayError: string | null;
    browsersPerDayIsLoading: boolean;

    browsersTable: BrowsersTableItem[];
    browsersTableRequestSource: CancelTokenSource | null;
    browsersTableError: string | null;
    browsersTableIsLoading: boolean;
}

const initialState: BrowsersState = {
    browsers: null,
    requestSource: null,
    error: null,
    isLoading: false,

    browsersPerDayLineChartData: null,
    browsersPerDayProgressionChart: null,
    browsersPerDay: null,
    browsersPerDayRequestSource: null,
    browsersPerDayError: null,
    browsersPerDayIsLoading: false,

    browsersTable: null,
    browsersTableRequestSource: null,
    browsersTableError: null,
    browsersTableIsLoading: false,
};

const browsersReducer = function (state: BrowsersState = initialState, action: ReducerAction): BrowsersState {
    switch (action.type) {
        case BROWSERS_DATA_IS_LOADING:{
            return {
                ...state,
                isLoading: true,
            };
        }

        case BROWSERS_GET_ALL_SUCCESS: {
            return {
                ...state,
                browsers: action.payload.data,
                requestSource: action.payload.requestSource,
                error: null,
                isLoading: false,
            };
        }

        case BROWSERS_GET_ALL_FAILURE: {
            return {
                ...state,
                error: action.payload.message,
                isLoading: false,
            };
        }

        case BROWSERS_PER_DAY_DATA_IS_LOADING:{
            return {
                ...state,
                browsersPerDayIsLoading: true,
            };
        }

        case BROWSERS_PER_DAY_GET_ALL_SUCCESS: {
            return {
                ...state,
                browsersPerDayLineChartData: action.payload.lineChartData,
                browsersPerDayProgressionChart: action.payload.progressionData,
                browsersPerDay: action.payload.data,
                browsersPerDayRequestSource: action.payload.requestSource,
                browsersPerDayError: null,
                browsersPerDayIsLoading: false,
            };
        }

        case BROWSERS_PER_DAY_GET_ALL_FAILURE: {
            return {
                ...state,
                browsersPerDayError: action.payload.message,
                browsersPerDayIsLoading: false,
            };
        }

        case BROWSERS_TABLE_DATA_IS_LOADING:{
            return {
                ...state,
                browsersTableIsLoading: true,
            };
        }

        case BROWSERS_TABLE_GET_ALL_SUCCESS: {
            return {
                ...state,
                browsersTable: action.payload.data,
                browsersTableRequestSource: action.payload.requestSource,
                browsersTableError: null,
                browsersTableIsLoading: false,
            };
        }

        case BROWSERS_TABLE_GET_ALL_FAILURE: {
            return {
                ...state,
                browsersTableError: action.payload.message,
                browsersTableIsLoading: false,
            };
        }

        default: {
            return state;
        }
    }
};

export default browsersReducer;
