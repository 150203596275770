import React from 'react';

import CompareStats from 'components/compare-stats/CompareStats';
import { UnknownValue } from 'constants/defaults';
import { isFunction } from 'lodash';
import { symbolizeNumber } from 'services/conversion/conversion';
import '../Progress.sass';
import ProgressBar from '../bar/ProgressBar';
import './ProgressInline.scss';

interface Props {
    title: string | React.ReactNode;
    progressPercentage?: number;
    percentage?: string | number;
    background?: string;
    barHeight?: string;
    number?: string | number;
    pastPercentageOrValue?: number;
    compare?: { percentage: number; diff?: number };
    hideDiff?: boolean;
    onClickOnTitle: () => void;
}

function ProgressInline({
    title,
    progressPercentage,
    percentage,
    background,
    barHeight,
    number,
    pastPercentageOrValue,
    compare,
    hideDiff,
    onClickOnTitle
}: Props) {
    const width: string = progressPercentage
        ? progressPercentage + '%'
        : percentage + '%';

    return (
        <div className="progress-inline-wrapper">
            <div className='progress-inline-title'>
                <p
                    onClick={() => title && onClickOnTitle?.()}
                    style={{ cursor: isFunction(onClickOnTitle) && title ? 'pointer': '' }}
                    className={!title ? '--isDisabled' : ''}
                >{title || UnknownValue}</p>
            </div>

            <ProgressBar
                background={background}
                barHeight={barHeight || '13px'}
                width={width}
            />

            <div className="progress-inline-stats">
                <h4>{symbolizeNumber(parseInt(number.toString()), 1, { convertToComma: true, shouldRound: true, ignoreFirstSixDigits: true })}</h4>

                {
                    compare && (
                        <CompareStats
                            percentage={compare.percentage}
                            pastPercentageOrValue={pastPercentageOrValue}
                            diff={compare.diff}
                            hideDiff={hideDiff}
                            percentageStyle={{ fontSize: '0.7rem' }}
                            iconStyle={{ marginTop: '3px' }}
                            diffStyle={{ fontSize: '0.45rem', marginBottom: '2px' }}
                            wrapperStyle={{ marginTop: '-2px', marginLeft: '7px', minWidth: 'unset', maxWidth: 'unset' }}
                        />
                    )
                }
            </div>

        </div>
    );
}

ProgressInline.defaultProps = {
    background: '#000',
    percentage: null,
    number: null,
    barHeight: '',
};

export default ProgressInline;
