import type { CancelTokenSource } from 'axios';
import type { ReducerAction } from 'shared/interfaces/store/action/reducerAction';
import type { Devices } from 'shared/interfaces/store/devices/devices';
import type { DevicesPerDay } from 'shared/interfaces/store/devices/devicesPerDay';
import type { DevicesTable } from 'shared/interfaces/store/devices/devicesTable';
import type { DevicesPerDayLineChartData } from './formatter';

import {
    DEVICES_DATA_IS_LOADING,
    DEVICES_GET_ALL_FAILURE,
    DEVICES_GET_ALL_SUCCESS,
    DEVICES_PER_DAY_DATA_IS_LOADING,
    DEVICES_PER_DAY_GET_ALL_FAILURE,
    DEVICES_PER_DAY_GET_ALL_SUCCESS,
    DEVICES_TABLE_DATA_IS_LOADING,
    DEVICES_TABLE_GET_ALL_FAILURE,
    DEVICES_TABLE_GET_ALL_SUCCESS
} from './devices.types';

export declare interface DevicesState {
    devices: Devices;
    requestSource: CancelTokenSource;
    error: string;
    isLoading: boolean;

    devicesTable: DevicesTable;
    devicesTableRequestSource: CancelTokenSource;
    devicesTableError: string;
    devicesTableIsLoading: boolean;

    devicesPerDayLineChartData: DevicesPerDayLineChartData;
    devicesPerDay: DevicesPerDay;
    devicesPerDayRequestSource: CancelTokenSource;
    devicesPerDayError: string;
    devicesPerDayIsLoading: boolean;
}

const initialState: DevicesState = {
    devices: null,
    requestSource: null,
    error: null,
    isLoading: false,

    devicesTable: null,
    devicesTableRequestSource: null,
    devicesTableError: null,
    devicesTableIsLoading: false,

    devicesPerDayLineChartData: null,
    devicesPerDay: null,
    devicesPerDayRequestSource: null,
    devicesPerDayError: null,
    devicesPerDayIsLoading: false,
};

const devicesReducer = function (state: DevicesState = initialState, action: ReducerAction): DevicesState {
    switch (action.type) {
        case DEVICES_DATA_IS_LOADING:{
            return {
                ...state,
                isLoading: true,
            };
        }

        case DEVICES_GET_ALL_SUCCESS: {
            return {
                ...state,
                devices: action.payload.data,
                requestSource: action.payload.requestSource,
                error: null,
                isLoading: false,
            };
        }

        case DEVICES_GET_ALL_FAILURE: {
            return {
                ...state,
                error: action.payload.message,
                isLoading: false,
            };
        }

        case DEVICES_TABLE_DATA_IS_LOADING:{
            return {
                ...state,
                devicesTableIsLoading: true,
            };
        }

        case DEVICES_TABLE_GET_ALL_SUCCESS: {
            return {
                ...state,
                devicesTable: action.payload.data,
                devicesTableRequestSource: action.payload.requestSource,
                devicesTableError: null,
                devicesTableIsLoading: false,
            };
        }

        case DEVICES_TABLE_GET_ALL_FAILURE: {
            return {
                ...state,
                devicesTableError: action.payload.message,
                devicesTableIsLoading: false,
            };
        }

        case DEVICES_PER_DAY_DATA_IS_LOADING:{
            return {
                ...state,
                devicesPerDayIsLoading: true,
            };
        }

        case DEVICES_PER_DAY_GET_ALL_SUCCESS: {
            return {
                ...state,
                devicesPerDayLineChartData: action.payload.lineChartData,
                devicesPerDay: action.payload.data,
                devicesPerDayRequestSource: action.payload.requestSource,
                devicesPerDayError: null,
                devicesPerDayIsLoading: false,
            };
        }

        case DEVICES_PER_DAY_GET_ALL_FAILURE: {
            return {
                ...state,
                devicesPerDayError: action.payload.message,
                devicesPerDayIsLoading: false,
            };
        }

        default: {
            return state;
        }
    }
};

export default devicesReducer;
